
function getFieldValue(listItem){
    let val
    let appForm = document.getElementById('app-form')


    if(listItem.type === 'radio'){
        let radios = Array.from(appForm.elements[listItem.selector])
        // loop through radio buttons and find checked one
        for(let i = 0; i < radios.length; i++){
            if(radios[i].checked){
                val = radios[i].value
                break
            }
        }

        return val

    }
    else if(listItem.type === 'checkbox'){
        val = []
        // get list of checkboxes with specified name
        var checkboxes = appForm.elements[listItem.selector];
        // loop through list of checkboxes
        for (let i=0; i < checkboxes.length; i++) {
            if ( checkboxes[i].checked ) { // radio checked?
                val.push(checkboxes[i].value); // if so, hold its value in val
            }
        }
        return val // return array of checked checkboxes values
    }
    else if(listItem.type === 'select-multiple'){
        val=[]
        let s = listItem.node
        let opt
        for(let i=0; i < s.options.length; i++){
            opt = s.options[i]
            if(opt.selected) val.push(opt.value)
        }
        return val
    }
    else{
        val = appForm.elements[listItem.selector].value
        return val

    }
}

//assemble a list of form logical inputs
//i.e. checkbox and radio groups are considered one logical element

//for inputs and selects other than groups, selector is ID
//for groups(checkboxes and radio buttons) selector is name attribute
function getFormInputsList(form){
    let fe = form.elements //initial array of form elements

    //filter out non-accepted types (submit, etc)
    let acceptedTypes = ['text', 'number', 'select-one', 'select-multiple', 'textarea', 'radio', 'checkbox']
    // let list = Array.from(fe).filter( (el, index) => {
    //     return acceptedTypes.indexOf(el.type) >= 0 
    // })
    let list = Array.from(fe).filter( (el, index) => acceptedTypes.indexOf(el.type) >= 0 )

    //map list to new list of objects with attributes element selector, element type, validation rules
    list = list.map( node => {
            return {node, selector: node.name, type: node.type, vData: node.getAttribute('data-validate'), dataScope: node.getAttribute('data-scope') }
            // node is JS node for element
            // selector is JS selector in forms.element['selector'] syntax
        }
    )

    return list
}

//validate form fields
//field argument must be in the form of form.elements['name']

function validateFormField(listItem){
    let validationData = listItem.vData
    if(validationData != null){
        let vlist = JSON.parse(validationData)
        let errorMsgs = []
        let errorDisplay = ''
        let fParent = listItem.node.closest('.form-elem-unit')
        let errorMsgEl = null;

        if(Array.isArray(vlist)){ //multiple validations
            for(var vo of vlist){
                if(! runValidationRule(listItem, vo) ){
                    errorMsgs.push(vo.errorMsg)
                    if(vo.type === 'notEmpty'){
                        //skip next test if notEmpty test not passed
                        //since tests are not really valid for empty values
                        //and you don't want to generate extra error message for that
                        break;
                    }
                }
            }
        }
        else{ //single validation
            if(! runValidationRule(listItem, vlist) ) errorMsgs.push(vlist.errorMsg)
        }

        if(errorMsgs.length === 0){
            errorMsgEl = fParent.querySelector('.error-msg')
            if(errorMsgEl != null) fParent.removeChild(errorMsgEl)
            return true;
        }
        else{
            errorDisplay = errorMsgs.join(' ');
            let existingErrorMsgEl = fParent.querySelector('.error-msg')

            if(!existingErrorMsgEl){
                errorMsgEl = document.createElement('span')
                errorMsgEl.setAttribute('class', 'error-msg') 
            }
            else{
                errorMsgEl = existingErrorMsgEl
            }

            errorMsgEl.innerHTML = '*' + errorDisplay
                       
            fParent.prepend(errorMsgEl)
            return false;
        }
    }
    else{
        return true;
    }
}

function runValidationRule(listItem, vo){
    //argument is validation rule object
    //returns true/false
    let appForm = document.getElementById('app-form')
    let field = appForm[listItem.selector]
    let val = field.value.trim()
    let inputGroupArray
    let chkdNum
    let passed = false

    switch(vo.type) {
        case 'notEmpty':
            passed = (val === '') ? false : true
            break
        case 'isAlpha':
            passed = (! /[^a-zA-Z0-9\s]/.test( val ))
            break
        case 'isAlphaPunct':
            passed = (! /[^a-zA-Z\s.,'"“‘”’\-!]/.test( val ))
            break
        case 'isAlphaNum':
            passed = (! /[^a-zA-Z0-9\s]/.test( val ))
            break
        case 'isAlphaNumPunct':
            passed = (! /[^a-zA-Z0-9\s.,'"“‘”’\-!]/.test( val ))
            break
        case 'eq':
            passed = (val === vo.value)
            break
        case 'not':
            passed = (val !== vo.value)
            break
        case 'lt':
            passed = (Number(val) < Number(vo.value))
            break
        case 'gt':
            passed = (Number(val) > Number(vo.value))
            break
        case 'lte':
            passed = (Number(val) <= Number(vo.value))
            break
        case 'gte':
            passed = (Number(val) >= Number(vo.value))
            break
        case 'isNum':
            passed = (! /[^0-9-]/.test( val ))
            break
        case 'isNumDecimal':
            passed = (! /[^0-9.-]/.test( val ))
            break
        case 'isNumDecimalComma':
            passed = (! /[^0-9.,-]/.test( val ))
            break
        case 'isEmail':
            passed = (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test( val ))
            break
        case 'isPhoneNum':
            passed = (/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test( val ))
            break
        case 'checkboxCheckedEQ': //number checked is equal to value
            inputGroupArray = document.getElementsByName(listItem.selector)
            chkdNum=0
            for(var i = 0; i < inputGroupArray.length; i++){
                if(inputGroupArray[i].checked) chkdNum++
            }
            passed = (chkdNum === Number(vo.value))
            break;
        case 'checkboxCheckedGT': //number checked is greater than value
            inputGroupArray = document.getElementsByName(listItem.selector)
            chkdNum=0
            for(i = 0; i < inputGroupArray.length; i++){
                if(inputGroupArray[i].checked) chkdNum++
            }
            passed = (chkdNum > Number(vo.value))
            break;
        case 'checkboxCheckedGTE': //number checked is greater than or equal to value
            inputGroupArray = document.getElementsByName(listItem.selector)
            chkdNum=0
            for(i = 0; i < inputGroupArray.length; i++){
                if(inputGroupArray[i].checked) chkdNum++
            }
            passed = (chkdNum >= Number(vo.value))
            break;
        case 'checkboxCheckedLT': //number checked is less than value
            inputGroupArray = document.getElementsByName(listItem.selector)
            chkdNum=0
            for(i = 0; i < inputGroupArray.length; i++){
                if(inputGroupArray[i].checked) chkdNum++
            }
            passed = (chkdNum < Number(vo.value))
            break;
        case 'checkboxCheckedLTE': //number checked is less than or equal to value
            inputGroupArray = document.getElementsByName(listItem.selector)
            chkdNum=0
            for(i = 0; i < inputGroupArray.length; i++){
                if(inputGroupArray[i].checked) chkdNum++
            }
            passed = (chkdNum <= Number(vo.value))
            break;

        default:
            break
    }

    return passed

}

//this is for a workaround for firefox browser allowing alphabetical entries in number input fileds
function allowNumbersOnly(e) {
    var code = (e.which) ? e.which : e.keyCode
    if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault()
    }
}

function allowNumbersCommaPeriodOnly(e) {
    var code = (e.which) ? e.which : e.keyCode
    if (code > 31 &&(code !== 44) &&(code !== 46) && (code < 48 || code > 57)) {
        e.preventDefault()
    }
}

function stringCaseFormat(str, type){
    switch (type) {
        case 'initialCaps':
            return str.charAt(0).toUpperCase() + str.substring(1)
            break
        case 'titleCase':
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            )
            break
        default:
            return str
            break
    }
}

function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}

function cleanNumberField(n){
    return Math.round(Number(n.replace(/,/g,'')))
}

function averageOfNums(){
	let args = Array.from(arguments)
	return args.reduce((total, n) => total + n, 0)/args.length
}





export { stringCaseFormat, getFormInputsList, validateFormField, getFieldValue, allowNumbersOnly, allowNumbersCommaPeriodOnly, numberWithCommas, cleanNumberField, averageOfNums }

