import React from 'react'
import { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import AppProvider from '../modules/app-context'
import ReportButtons from './ReportButtons'
import PageCountWrap from './PageCountWrap'
import Cover from './report-pages/Cover'
import TOC from './report-pages/TOC'
import AboutYourBusiness from './report-pages/AboutYourBusiness'
import ProtectingYourLegacy from './report-pages/ProtectingYourLegacy'
import AboutUs from './report-pages/AboutUs'


class Report extends Component{

    componentDidMount(){
        this.refs.toc.populate()
        window.scrollTo(0,0)
    }

    componentDidUpdate(){
        //this.refs.toc.populate()
        window.scrollTo(0,0)
        
    }

    render(){
        return(
            <div id="report">
                <ReportButtons />
                <PageCountWrap>
                    <Cover />
                    <TOC ref="toc" />
                    <AboutYourBusiness />
                    <ProtectingYourLegacy />
                    <AboutUs />
                </PageCountWrap>
            </div>
        )
    }
}

Report.contextType = AppProvider.appContext

export default Report