import React from 'react'
import { Component } from 'react'
// import PropTypes from 'prop-types'
import AppProvider from '../../modules/app-context'

class Cover extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        let logoStyle = {
            marginBottom: '1.25em'
        }

        let h1Style = {
            lineHeight: '.9em'
        }

        let h3Style = {
            marginTop: '-1.75em'
        }

        let newDate = new Date()
        let year = newDate.getFullYear();

        return(
            <section className="cover">
                <div className="page has-masthead-img">
                    <div className="inner-wrap">
                        
                        <img src="img/cover-masthead.jpg" alt="" className="masthead" />
                        <div className="hangpoint hangpoint-5">
                            <img src="img/guardian-logo.png" width="115" style={logoStyle} />
                            <h1 style={h1Style}>Your Business,<br />Your Future</h1>
                            <h3 id="cover-subhead1" style={h3Style}>Business valuation and planning profile for</h3>
                            <h2 className="company-name">{this.context.bizInfo.name}</h2>
                            <div className="agent-info">
                                <h4 className="cover-subhead2">Presented by</h4>
                                <p>
                                <strong>{this.context.agentInfo.name}</strong><br/>
                                {this.context.agentInfo.phone}<br />
                                {this.context.agentInfo.email}
                                </p>
                            </div>
                        </div>
                        <div className="footnotes">
                            <p>Guardian, its subsidiaries, agents, and employees do not provide tax, legal, or accounting advice. Consult your tax, legal, or accounting professional regarding your individual situation. The information included herein is general information that may be helpful, but Guardian makes no representations with respect to the accuracy, currentness, or completeness of that information.  Any and all business valuations contained herein were prepared to the best of Guardian’s ability based upon information that you provided to Guardian, but should not be relied upon for tax filing or other accounting purposes.  To obtain a valuation of your business for tax filing purposes, please consult your tax advisor or CPA.</p>
                            <p>&copy;{year}, The Guardian Life Insurance Company of America, New York, NY<br/>GUARDIAN<sup>®</sup> is a registered trademark of The Guardian Life Insurance Company of America<sup>®</sup>.</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

Cover.contextType = AppProvider.appContext
export default Cover
