import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'

class AboutUs extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        return(
            <section className="about-us toc-item" id="about-us" data-toc-level="1" data-toc-heading="About Us" data-toc-show="no" data-toc-style="epilogue">
            <div className="page has-masthead-img">
                <div className="inner-wrap">
                    <img src="img/about-us-masthead.jpg" alt="" className="masthead" />
                    <div className="hangpoint hangpoint-5">
                        <h1>About Us</h1>
                    </div>
                </div>
            </div>    
            
            <div className="page toc-item" data-toc-level="2" data-toc-heading="About Us" data-toc-show="yes" data-toc-style="epilogue">
                <div className="inner-wrap">
                    <PageHeader />
                    <div className="hangpoint hangpoint-1">
                        <h1>About Us</h1>
                    </div>
                    <div className="hangpoint hangpoint-2">
                        <div className="column cols-4of6">
                                <p>The Guardian Life Insurance Company of America is one of the largest and oldest mutual life insurance companies in America. Since 1860, Guardian has been providing financial strategies to help individuals, families and small business owners achieve financial confidence. In addition, as a mutual life company owned by its policyholders, Guardian operates for the benefit of its policyholders, rather than outside stockholders. The heritage of a customer-focused approach carries over into each business line and subsidiary. It also means a commitment to long-term strategic thinking, rather than short-term profits.  While the markets may change, Guardian’s timeless values of doing the right thing, holding itself to the highest standards, and focusing on people do not.</p>
                                <p>At Guardian, we believe that a successful plan lies in a holistic, team-based approach, providing a single point of contact to help simplify nearly every aspect of the planning process from business to personal — no matter how challenging. When you partner with Guardian, you’ll benefit from the collective expertise of a dedicated team of professionals who can best match your financial goals and objectives with the most effective solutions possible.</p>
                                
                                <h3>A Guardian financial professional &mdash; delivering powerful individualized support</h3>
                                <p>Guardian’s network of financial professionals offers years of experience working with high net worth individuals &mdash; to help them navigate the complexities of the business and wealth transfer markets.  By taking the time to understand and consider your complete financial picture, our financial professionals help craft a financial strategy that best reflects your individual objectives.</p>
                                <p>While Guardian’s agencies and financial professionals provide individualized support for you in your community, there is a second part of the team which provides support at a higher level and empowers them to strategically address your financial challenges.</p>
                        </div>
                    </div>
                    <PageFooter sectionTitle="About Us" />
                </div>
            </div>
    
            <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="column cols-4of6">
                            <div className="hangpoint hangpoint-1">
                                <h3>The Business Resource Center (BRC)</h3>
                                <p>In many instances, it may be appropriate to collaborate with other professionals, such as attorneys and CPAs. With a Guardian financial professional as the conduit, you’ll also benefit from the broader network and intellectual capital of Guardian’s Business Resource Center &mdash; a team of professionals backed by over 255 cumulative years of advance business, individual, and wealth transfer planning expertise. The team of professionals at the BRC provides a wealth of resources and insight into a wide range of planning strategies in multiple areas, including business succession, executive benefits, estate planning, and inter-generational wealth transfer.</p>
                                <p><br />2023-156597 Exp. 8/25</p>
                            </div>
                        </div>
                        
                        <PageFooter sectionTitle="About Us" />
                    </div>
                </div>
        </section>
        )
    }

}

AboutUs.contextType = AppProvider.appContext
export default AboutUs
