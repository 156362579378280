import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'

class LifeIns extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        return(
            <section className="life-insurance counted-headings toc-item" id="life-insurance" data-toc-level="2" data-toc-heading="Protecting Family & Business: Life Insurance" data-toc-show="yes" data-toc-style="default">
                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <h1>Life<br />Insurance</h1>
                        </div>

                        <div className="hangpoint hangpoint-3">
                            <div className="column cols-3of6 float-left">
                                <h2>Protecting<br />key employees and<br />your business</h2>
                                <p>Offering critical individuals permanent life insurance is an increasingly popular recruitment and retention tool. Most companies use one of two options:</p>
                                <h3 className="counted-heading"><span className="counter"></span>Executive Bonus Plans</h3>
                                <ul>
                                    <li>Business pays the premiums on a life insurance policy owned by you or your key employee.</li>
                                    <li>Death benefit paid to family members, while the policy’s cash value can provide supplemental retirement income through withdrawals and loans.<sup>1</sup></li>
                                    <li>The insured is taxed on the premiums as additional compensation; however, the business can provide extra cash to offset the income tax liability.</li>
                                    <li>The employee’s access to the policy’s cash value<sup>2</sup> can be restricted for a period of time, further motivating him or her to remain with the company.</li>
                                </ul>
                            </div>
                            <div className="column cols-3of6 float-left">
                                <figure className="overflow-visible" style={{marginTop: '-11em !important'}}>
                                    <img src="img/life-insurance.svg" className="fullwidth" alt="Life Insurance diagram"/>
                                </figure>
                            </div>
                        </div>
                        
                        <div className="footnotes">
                            <p><sup>1</sup>Policy benefits are reduced by any outstanding loan or loan interest and/or withdrawals. Dividends, if any, are affected by policy loans and loan interest. Withdrawals above the cost basis may result in taxable ordinary income. If the policy lapses, or is surrendered, any outstanding loans considered gain in the policy may be subject to ordinary income taxes. If the policy is a Modified Endowment Contract (MEC), loans are treated like withdrawals, but as gain first, subject to ordinary income taxes. If the policy owner is under age 59½, any taxable withdrawal may also be subject to a 10% federal tax penalty.</p>
                            <p><sup>2</sup>Some whole life policies don’t have any cash values in years one or two. Whole life insurance should be considered for its long term value. Early cash value accumulation and early payment of dividends depend upon policy type and/or policy design, and cash value accumulation is offset by insurance and company expenses.  Consult with your Guardian representative and refer to your whole life insurance illustration for more information about your particular life insurance policy.</p>
                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div> 
                </div>
                

                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <div className="column cols-3of6 float-left">
                                <h3 className="counted-heading"><span className="counter"></span>Split Dollar Arrangements</h3>
                                <p>While there are two ways to design split dollar arrangements, the loan regime method is the most<br />popular in the market.</p>
                                <ul>
                                    <li>Business pays the premium for life insurance on you or a key employee.</li>
                                    <li>Business receives back a collateral assignment in the policy equal to the premiums paid.</li>
                                    <li>The premiums are treated as loans, and the insured either pays interest on the loan or pays taxes on the interest that is “imputed” on the loans.</li>
                                    <li>Death benefit may be income tax-free to the designated beneficiary.</li>
                                    <li>Business receives its premium loans back at death, or if the employee leaves the company.</li>
                                </ul>
                            </div>
                            <div className="column cols-3of6 float-left">
                                <figure className="overflow-visible" style={{marginTop: '-1.5em !important'}}>
                                    <figcaption>This is what a loan regime split dollar arrangement looks like:</figcaption>
                                    <img src="img/split-dollar-arrangement-diagram.svg" className="fullwidth" alt="Life Insurance diagram"/>
                                </figure>
                            </div>
                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div> 
                </div>
            </section>
        )
    }

}

LifeIns.contextType = AppProvider.appContext
export default LifeIns