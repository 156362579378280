import React from 'react'
import { Component } from 'react'
import AppProvider from '../modules/app-context'

class ReportButtons extends Component{

    printReport(){
        let result = window.confirm("To save as a PDF, please select the Save as PDF printer driver in the Print Dialog box which will appear next. Please be sure to set page margins to 0 and scaling to 100%.");
 
              if(result)  {
                window.print()
              } else {
                  //alert("Bye!");
              }
        
    }

    render(){
        return(
            <div className="print-action-buttons">
                <div className="form-buttons">
                    <button className="btn" title="Close" tabIndex="1" onClick={this.context.closeReport} >Close</button>
                    <button className="btn mint"title="Print PDF Report" tabIndex="2" onClick={this.printReport}>Save PDF Report</button>
                </div>
            </div>
        )
    }
}

ReportButtons.contextType = AppProvider.appContext

export default ReportButtons