import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'

class KeyPerson extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        return(
            <section className="key-person-insurance toc-item" id="key-person-insurance" data-toc-level="2" data-toc-heading="Talent Retention: Key Person Insurance" data-toc-show="yes" data-toc-style="default">
                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <h1>Key Person<br />Insurance</h1>
                                <div className="column cols-3of6 float-left">
                                    <h2>Protecting against the loss of key talent</h2>
                                    <p>Profits are made by people, not machines.</p>
                                    <p>While hard assets are vital to a business’s success, they’re no substitute for the experience, knowledge and special skills of key employees. The death or disability of key talent &mdash; whether a top salesperson, executive, technical specialist or financial wizard &mdash; can quickly cripple your ability to make a profit.</p>
                                    <h4>What is Key Person Insurance?</h4>
                                    <ul>
                                        <li>It can provide for a desired amount of cash at the precise moment it’s needed: upon the death or disability of a key employee.<sup>1</sup></li>
                                        <li>Your business is the applicant, owner, premium payer and beneficiary of the policy.</li>
                                        <li>Its cash value can provide you with a reserve that can be accessed via loans and withdrawals.<sup>2</sup></li>
                                    </ul>
                                    <h4>How does it work?</h4>
                                    <ol>
                                        <li>Business owner notifies the key employee that life insurance (and/or disability income insurance) will be purchased on his/her life.</li>
                                        <li>Key employee consents to the purchase of the insurance.</li>
                                        <li>In the event the key employee dies, the policy’s benefits are paid directly to the business.</li>
                                    </ol> 
                                </div>
                                <div className="column cols-3of6 float-right">
                                    <figure className="fullwidth align-center">
                                        <img src="img/key-person-insurance.svg" className="fullwidth" alt="Key person insurance"/>
                                    </figure>
                                    
                                </div>
                            </div>
    
                            <div className="footnotes">
                                    <p><sup>1</sup>Employer-owned life insurance may be received income tax free by the business only if IRC 101(j) is complied with.</p>
                                    <p><sup>2</sup>Policy benefits are reduced by any outstanding loan or loan interest and/or withdrawals. Dividends, if any, are affected by policy loans and loan interest. Withdrawals above the cost basis may result in taxable ordinary income. If the policy lapses, or is surrendered, any outstanding loans considered gain in the policy may be subject to ordinary income taxes. If the policy is a Modified Endowment Contract (MEC), loans are treated like withdrawals, but as gain first, subject to ordinary income taxes. If the policy owner is under age 59½, any taxable withdrawal may also be subject to a 10% federal tax penalty.</p>
                            </div>
    
                            <PageFooter sectionTitle="Protecting Your Legacy"/>
                        </div> 
                    </div>
                    

                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <div className="column cols-3of6 float-left">
                                    <ol start="4">
                                        <li>If the employee becomes disabled and the business protected itself with key employee disability income insurance, then the disability policy’s benefits are paid directly to the business.</li>
                                    </ol>
                                    <h4>The benefits of Key Person Insurance</h4>
                                    <ul>
                                        <li>Provides money for expenses created by a key employee’s death or disability, such as hiring a replacement and planning for potential revenue loss.</li>
                                        <li>Adds a business asset, which can be used for business collateral benefits obligations, even redeeming the business interest of deceased and disabled owners.</li>
                                        <li>Assures banks, creditors and valued partners that a business has the resources to continue operating without interruption.</li>
                                    </ul>
                                </div>
                                <div className="column cols-3of6 float-right">
                                    <div className="sidebar">
                                            <h3>How much Key Person Insurance is enough?</h3> 
                                            <p>That depends on:</p> 
                                            <ul>
                                                <li>The key employee’s impact on future revenue and profits</li>
                                                <li>Business debt obligations directly impacted by the employee’s productivity</li>
                                                <li>The cost to hire and train a replacement for the key employee</li>
                                                <li>Some multiple of the key employee’s present salary</li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
        
                            <PageFooter sectionTitle="Protecting Your Legacy"/>
                        </div> 
                    </div>
                    

            </section>
        )
    }

}

KeyPerson.contextType = AppProvider.appContext
export default KeyPerson