import React, {Component} from 'react';

const AppContext = React.createContext();

class AppProvider extends Component{

    constructor(props){
        super(props)
    }
    

    updateState = (data) => {
        return new Promise((resolve) => {
            this.setState(data, resolve)
        })
    }

    showForm = (visibility) => {
        let formEl = document.getElementsByClassName('.app-form-main-wrap')
        if(visibility){
            formEl.classList.remove('hidden')
        }
        else{
            formEl.classList.add('hidden')
        }
    }

    showReport = () => {
        let newState = {...this.state, ...{appState: {printScreen: true}}}
        this.setState(newState)
    }

    closeReport = () => {
        let newState = {...this.state, ...{appState: {printScreen: false}}}
        this.setState(newState)
    }

    state = {
        agentInfo: {
          name: '',
          email: '',
          phone: ''

        },
        bizInfo: {
            name: '',
            industry: '',
            taxType: '',
            nYearsInBiz: '',
            nOwners: '',
            state: '',
            nEmployees: '',
            nKeyEmployees: '',
            familyOwned: ''
        },
        bizFinancials: {
            corpAssets: '',
            corpLiabilities: '',
            annualEarnings: '',
            capRate: '',
            expAnnualROR: '',
            nYearsGoodwill: ''
        },
        reportOpts: {
            esop: '',
            concepts: ''
        },
        appState: {
            printScreen: false
        },
        updateState: this.updateState,
        showForm: this.showForm,
        showReport: this.showReport,
        closeReport: this.closeReport
    }
    

    render(){
        return(
            <AppContext.Provider value ={this.state}>
                {this.props.children}
            </AppContext.Provider>
        )
    }

}

AppProvider.appContext = AppContext;

export default AppProvider

