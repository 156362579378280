import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types'; 
import AppProvider from '../modules/app-context'

class PageFooter extends Component{
    render(){
        return(
            <footer>
                <div className="section-title">{this.props.sectionTitle}</div>
                <div className="page-num"></div>
            </footer>
        )
    }
}

PageFooter.propTypes = {
    sectionTitle: PropTypes.string,
}

PageFooter.defaultProps = {
    sectionTitle: "",
}

PageFooter.contextType = AppProvider.appContext

export default PageFooter