import React from 'react'
import { Component } from 'react'
import AppProvider from '../../modules/app-context'

class TOC extends Component{
    
    constructor(props){
        super(props)
    }

    state = {
        tocObj: null
    }


    populate = () => {
        let report = document.querySelector('#report')
        let toc = []
        let o, cNodes, pNode, pNum

        let pages = (document.querySelector('.page-count').querySelectorAll('.page'))

        //dynamically assign data-pagenum to each page
        //since we can't get page number from .page-num:before
        //since pseudo elements are not in the DOM
        pages.forEach(
            (page, i) => {
                page.setAttribute('data-pagenum', i+1)
            }
        )
        
        //Level 1
        let nodesLevel1= report.querySelectorAll('[data-toc-level="1"]')

        nodesLevel1.forEach(
            (node) => {
                pNode = (node.classList.contains('page')) ? node : node.querySelector('.page')
                
                o = {
                    el: node, 
                    heading: node.getAttribute('data-toc-heading'),
                    type: node.getAttribute('data-toc-type'),
                    show: node.getAttribute('data-toc-show'),
                    page: pNode.getAttribute('data-pagenum'),
                    childNodes: []
                }
                toc.push(o)
            }
        )

        //Level 2
        toc.forEach(
            (node) => {
                cNodes = node.el.querySelectorAll('[data-toc-level="2"]')

                cNodes.forEach(
                    (cNode) => {
                        pNode = (cNode.classList.contains('page')) ? cNode : cNode.querySelector('.page')

                        o = {
                            el: cNode,
                            heading: cNode.getAttribute('data-toc-heading'),
                            type: cNode.getAttribute('data-toc-type'),
                            show: cNode.getAttribute('data-toc-show'),
                            page: pNode.getAttribute('data-pagenum'),
                            childNodes: []
                        }
                        node.childNodes.push(o)
                        console.log(o)
                    }
                )
                
            }
        )
        
        this.setState({...this.state, ...{tocObj: toc}})
    }

    tocListing = () => {
        let listing = []
        let item, childItem, childItems, listArray
        if(this.state.tocObj !== null){

            for(var i = 0; i < this.state.tocObj.length; i++){
                item = this.state.tocObj[i]
                childItems = item.childNodes
                listArray = []

                for(var j = 0; j < childItems.length; j++){
                    childItem = childItems[j]
                    if(childItem.show === 'yes') listArray.push(<li><span className="label">{childItem.heading}</span><span className="toc-pagenum-listing">{childItem.page}</span></li>)
                }
                 
                if(item.show === 'yes') listing.push(<h3>{item.heading}</h3>)
                listing.push(<div className="toc-section"><ul>{listArray}</ul></div>)
            }
        }
        return listing
    }

    render(){


        return(
            <section className="toc">
                <div className="page">
                    <div className="inner-wrap">
                        <div className="hangpoint hangpoint-1">
                            <h1>Table of Contents</h1>
                        </div>

                        <div className="hangpoint hangpoint-3">
                            <div className="column cols-4of6">
                            {this.tocListing()}
                            </div>
                        </div>
                        
                        <footer>
                            <div className="page-num"></div>
                        </footer>
                        
                    </div>
                </div>
            </section>
        )
    }

}

TOC.contextType = AppProvider.appContext
export default TOC