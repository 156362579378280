import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'

class RecruitRetention extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        return(
            <section className="recruitment-and-retention toc-item" id="recruitment-and-retention" data-toc-level="2" data-toc-heading="Recruitment & Retention: Plans to Consider" data-toc-show="yes" data-toc-style="default">
                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <h1>Recruitment<br />and Retention</h1>
                            <div className="column cols-4of6">
                                <h2>Gain a competitive edge<br />in a tight talent market.</h2>
                                <p>Chances are, you’re competing just as fiercely for talented people as you are for business. Helping your company stand out in such a tight labor market is essential. And if you’re fortunate enough to land key talent, how can you be sure you’ll keep it?</p>
                                <p>Qualified retirement plans aren’t enough. You’ll need an innovative benefit plan that recognizes the value of key employees. Below are some benefit options to consider:</p>
                            </div>
                        </div>

                        <div className="hangpoint hangpoint-6" style={{marginTop: '-4em'}}>
                            <table className="fullwidth has-column-headings zebra zebra-blue zebra-even align-center no-row-rules">
                                <thead>
                                <tr>
                                    <th>Executive<br />Benefit</th>
                                    <th>Current Tax<br />Deduction</th>
                                    <th>Executive<br />Deferring Income</th>
                                    <th>Employer Cost<br />Recovery</th>
                                    <th>Tax Deduction when<br />benefit paid</th>
                                    <th>“Golden<br />handcuffs”</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td>Executive<br />Bonus</td>
                                    <td>Yes</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Retention<br />Bonus</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Split Dollar</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Deferred<br />Compensation</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div> 
                </div>
            </section>
        )
    }

}

RecruitRetention.contextType = AppProvider.appContext
export default RecruitRetention