import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types'
import AppProvider from '../modules/app-context'
//import { saveLocalData, getLocalData } from '../modules/local-storage';
import { getFormInputsList, validateFormField, getFieldValue, allowNumbersOnly, allowNumbersCommaPeriodOnly } from '../modules/functions';

class AppForm extends Component{

    constructor(props){
        super(props)
        this.render = this.render.bind(this)
        this.clearData = this.clearData.bind(this)
        this.pullSavedData = this.pullSavedData.bind(this)
    }


    setInstantValidate = () => { //set fields on form to validate individually onBlur and on update
        //get list of form fields
        let appForm = document.getElementById('app-form')
        let ffList = getFormInputsList(appForm)
        
        ffList.forEach( (listItem, i) => {

            //First handle blur events
            
            if(listItem.type === 'checkbox'){
                //checkboxes need to be treated a little differently
                //we don't want to run validation while user is tabbing focus through the checkboxes
                //until user defocuses on the whole group
                //and if there is an error msg, then run validation each time a checkbox is checked

                let chkboxGroup = appForm[listItem.selector]

                listItem.node.addEventListener('blur', () => {
                    chkboxGroup.validateTimeout = setTimeout( () => validateFormField(listItem), 250)
                })

                listItem.node.addEventListener('focus', () => clearTimeout(chkboxGroup.validateTimeout) ) 
                
            }
            else{
                //for radio, text, number, select, select-multi, textarea
                listItem.node.addEventListener('blur', () => validateFormField(listItem))
            }
            
            //now handle change and input events

            if( (listItem.type !== 'text') && (listItem.type === 'number') && (listItem.type !== 'textarea')){
                //for select lists, checkboxes and radios
                listItem.node.addEventListener('change', () => validateFormField(listItem))
            }
            else{ //text fields and text areas
                let fParent = listItem.node.closest('.form-elem-unit')

                listItem.node.addEventListener('input', () => {
                    if(fParent.getElementsByClassName('error-msg').length > 0) validateFormField(listItem)
                    //only run validation for inputs if there is an error message alread
                    //otherwise would be too annoying for text fields to run validate all the time
                })
            }
            //also add onchange event handler to number fields
            if(listItem.type === 'number'){
                //listItem.node.onchange = (e) => validateFormField(listItem)
                listItem.node.addEventListener('change', () => validateFormField(listItem))
                listItem.node.addEventListener('input', () => validateFormField(listItem))
            }

            
            
        } )
    }

    writeFieldDatatoState(fieldItem){
        //fieldItem arg is listItem from formfield List
        let newData = {}
        let newState = {}
        let dataScope = JSON.parse(fieldItem.dataScope)
        newData[dataScope.field] = getFieldValue(fieldItem)
        newState[dataScope.group] = {...this.context[dataScope.group], ...newData}
        this.context.updateState(newState)
    }

    writeGroupDataToState(group, groupObj){
        //writes Grouped data to state (e.g. field data belonging to one section in the form)
        //group argument is the data group, e.g. bizInfo, agentInfo
        let newState = {}
        newState[group] = {...this.context[group], ...groupObj}
        this.context.updateState(newState)
    }

    syncFieldstoState = (contextObj) => {
        //get list of form fields
        let appForm = document.getElementById('app-form')
        let ffList = getFormInputsList(appForm)

        ffList.forEach( (listItem, i) => {  
           
            // let dataScope = JSON.parse(listItem.dataScope)

            if((listItem.type === 'text') || (listItem.type === 'textarea')|| (listItem.type === 'number')){

                listItem.node.addEventListener('blur', () => {
                    this.writeFieldDatatoState(listItem)
                })
            }
            else{
                //radio, checkbox, select, select-multiple
                listItem.node.addEventListener('change', () => {
                    this.writeFieldDatatoState(listItem)
                })
            }
        })
    }

    validateAllFields = () => {
        //get list of form fields
        let appForm = document.getElementById('app-form')
        let ffList = getFormInputsList(appForm)
        
        ffList.forEach( (listItem, i) => {
            let f = appForm.elements[listItem.selector]
            validateFormField(listItem, f, listItem.vData)
        } )
    }

    saveData = (e) => {
        //validate form fields
        if(e)e.preventDefault()
        this.validateAllFields()

        let appForm = document.getElementById('app-form')
        let ffList = getFormInputsList(appForm)

        localStorage.setItem('agentInfo', JSON.stringify(this.context.agentInfo))
        localStorage.setItem('bizInfo', JSON.stringify(this.context.bizInfo))
        localStorage.setItem('bizFinancials', JSON.stringify(this.context.bizFinancials))
        localStorage.setItem('reportOpts', JSON.stringify(this.context.reportOpts))
        localStorage.setItem('savedData', "true")

    }

    async pullSavedData(){
        //pulls saved data from localStorage object and 
        //populates form fields and updates state
        if(localStorage.savedData){
            let retrievedData = {}
            retrievedData.agentInfo = JSON.parse(localStorage.agentInfo)
            retrievedData.bizInfo = JSON.parse(localStorage.bizInfo)
            retrievedData.bizFinancials = JSON.parse(localStorage.bizFinancials)
            retrievedData.reportOpts = JSON.parse(localStorage.reportOpts)

            let newState = {...this.context, ...retrievedData}
            await this.context.updateState(newState)
            this.formShowStateData()
        }   
    }

    removeErrorMsgs = () => {
        let appForm = document.getElementById('app-form')
        appForm.querySelectorAll('.error-msg').forEach((elem) => {
            elem.parentNode.removeChild(elem)
        })
    }

    formShowStateData = (validateStatus=true) => {
        let stateData = this.context
        let appForm = document.getElementById('app-form')
        let ffList = getFormInputsList(appForm)
        let j=0;

        for (var group in stateData){
            //keep track of field values by group,
            //then write values by group to state
            if((typeof stateData[group] !== 'function') && (group !== 'appState')){
                //exlcude functions passed in context and
                //process only data objects


                for (var item in stateData[group]){
                    //pull out all fields with data group that matches group in loop
                    // and placein array f
                    //there should only be one item in array for single-type fields and select lists
                    //and multiple items for radios and checkboxes,
                    //where we'll only work with the first instance to get attributes


                    let f = ffList.filter((field) => {
                        var dataScope = JSON.parse(field.dataScope)     
                        if((dataScope.group === group) && (dataScope.field === item)) {
                            return true;
                        }
                    })

                    if(f.length > 0){
                        let fieldRef = f[0]
                        let fieldType = fieldRef.type
                        let val = stateData[group][item]
                        let selIndex
                
                        if( (fieldType === 'text') || (fieldType === 'number') || (fieldType === 'textarea')){
                            fieldRef.node.value = val
                        }
                        else if(fieldType === 'select-one'){
                            selIndex = fieldRef.node.querySelector('[value="' + val + '"]').index
                            fieldRef.node.selectedIndex = selIndex
                        } 
                        else if(fieldType === 'select-multiple'){
                            let selected = val
                            
                            if(val !== ''){
                                for (var i in selected){
                                    fieldRef.node.querySelector('[value="' + selected[i] + '"]').selected = true
                                }
                            }
                            else{
                                fieldRef.node.querySelectorAll('option').forEach((item) => item.selected = false)
                            }
                            
                        }
                        else if(fieldType === 'radio'){
                            if(val !== ''){
                                for( i in f ){
                                    if(f[i].node.getAttribute('value') === val) f[i].node.checked = true
                                }
                            }
                            else{
                                for( i in f ){
                                    f[i].node.checked = false
                                }
                            }
                            
                        }
                        else if(fieldType === 'checkbox'){
                            if(val !== ''){
                                for( i in f){
                                    let tVal = f[i].node.getAttribute('value')
                                    //check to see if selection array contains value
                                    if ( val.includes(tVal) ) f[i].node.checked = true
                                }
                            }
                            else{
                                for(i in f){
                                    f[i].node.checked = false;
                                }
                            }
                        }
                    }
                }
            }
        }
        if(validateStatus){
            this.validateAllFields()
        }
    }

    async clearData(){
        localStorage.clear()

        let blankStateData = {
            agentInfo: {
              name: '',
              email: '',
              phone: ''
            },
            bizInfo: {
                name: '',
                industry: '',
                taxType: '',
                nYearsInBiz: '',
                nOwners: '',
                state: '',
                nEmployees: '',
                nKeyEmployees: '',
                familyOwned: ''
            },
            bizFinancials: {
                corpAssets: '',
                corpLiabilities: '',
                annualEarnings: '',
                capRate: '',
                expAnnualROR: '',
                nYearsGoodwill: ''
            },
            reportOpts: {
                esop: '',
                concepts: ''
            }
        }
        let newState = {...this.context, ...blankStateData}
        await this.context.updateState(newState)
        this.removeErrorMsgs()
        this.formShowStateData(false)
    }

    showErrorScreen = (visibility) => {
        let el = document.querySelector('.error-badge-wrap')
        if(visibility){
            el.classList.remove('hidden')
            setTimeout(()=>el.classList.remove('transparent'), 500)
            
            
        }
        else{
            setTimeout(()=>el.classList.add('hidden'), 500)
            el.classList.add('transparent')
        }
    }

    showReport = (e) =>{
        e.preventDefault()
        this.validateAllFields()
        this.saveData()

        setTimeout(() => { //introduce slight pause to avoide errors
            if(document.querySelectorAll('.error-msg').length > 0){
                this.showErrorScreen(true)
                this.context.closeReport()
            }
            else{
                this.context.showReport()
            }
        }, 500)
    }
    
    componentDidMount(){
        //let contextObj = this.context
        this.pullSavedData()
        this.setInstantValidate()
        this.syncFieldstoState(this.context)

        //workaround for Firefox not enforcing input type=number, allows alphabetical chars by default
        document.querySelectorAll('input[type="number"]').forEach( (item) => {
            item.addEventListener('keypress', (e) => allowNumbersOnly(e))
        })

        //enforce number, period and comma for input type=text patter=number when you need to enter
        //a number like 50,000 or 50.678
        document.querySelectorAll('input[type="text"][pattern="number"]').forEach( (item) => {
            item.addEventListener('keypress', (e) => allowNumbersCommaPeriodOnly(e))
        })
        

    }

    shouldComponentUpdate(){
        return true;
    }


    render(){
        return(
            
            <div className="app-form-main-wrap">
                <div className="error-badge-wrap hidden transparent">
                    <div className="error-badge"><p>Please correct all field errors shown in RED before printing.</p><div className="close-btn" onClick={()=>this.showErrorScreen(false)}>Close<span className="icon"></span></div></div>
                </div>
                <header className="app-header" id="top">
                    <img src="img/guardian-logo.png" className="logo" alt="Guardian" />
                    <h1>Business Valuation Analysis</h1>
                    <a href="#top" className="clear-data" tabIndex={1} onClick={this.clearData}>Clear Data</a>
                </header>
                <form className="app-form" id="app-form">
                    <p>*All fields are required</p>
                    <h2>Agent Information</h2>
                    <div className="form-row">
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="agent-name">Agent Name</label><input type="text" name="agent-name" id="agent-name" ref="agentName" maxLength={72} tabIndex={2} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."},{"type": "isAlphaNumPunct", "errorMsg": "Please enter a valid name."}]' data-scope='{"group": "agentInfo", "field": "name"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="agent-email">Email</label><input type="text" name="agent-email" id="agent-email" ref="agentEmail" maxLength={72} tabIndex={3} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."},{"type": "isEmail", "errorMsg": "Please enter a valid email address."}]' data-scope='{"group": "agentInfo", "field": "email"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="agent-phone">Phone</label><input type="text" name="agent-phone" id="agent-phone" ref="agentPhone" maxLength={20} tabIndex={4} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."},{"type": "isPhoneNum", "errorMsg": "Please enter a valid phone number."}]' data-scope='{"group": "agentInfo", "field": "phone"}' />
                        </div>  
                    </div>

                    <h2>Business Background</h2>
                    <div className="form-row">
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-name">Business Name</label><input type="text" name="biz-name" id="biz-name" ref="biz-name" maxLength={72} tabIndex={5} data-validate='{"type": "notEmpty", "errorMsg": "This field cannot be empty."}' data-scope='{"group": "bizInfo", "field": "name"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-email">Industry</label><input type="text" name="biz-industry" id="biz-industry" ref="biz-industry" maxLength={72} tabIndex={6} data-validate='{"type": "notEmpty", "errorMsg": "This field cannot be empty."}'  data-scope='{"group": "bizInfo", "field": "industry"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-tax-type">Tax Type</label>
                            <select name="biz-tax-type" id="biz-tax-type" ref="biz-tax-type" tabIndex={7} data-validate='{"type": "notEmpty", "errorMsg": "No tax type selected."}' data-scope='{"group": "bizInfo", "field": "taxType"}'>
                                <option value="">---Select Tax Type ---</option>
                                <option value="Corporation">Corporation</option>
                                <option value="Limited Liability Corporation">Limited Liability Corporation</option>
                                <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                                <option value="Partnership">Partnership</option>
                                <option value="S Corporation">S Corporation</option>
                                <option value="C Corporation">C Corporation</option>
                                <option value="Sole Proprietorship">Sole Proprietorship</option>
                            </select>
                        </div>  
                    </div>
                    <div className="form-row">
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-num-years">Number of Years in Business</label><input type="number" name="biz-num-years" ref="biz-num-years" maxLength={3} min={0} tabIndex={8} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "gte", "value": "0", "errorMsg": "Value must be 0 or greater."}]'  data-scope='{"group": "bizInfo", "field": "nYearsInBiz"}'/>
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-num-owners">Number of Owners</label><input type="number" name="biz-num-owners" ref="biz-num-owners" maxLength={4} min={1} tabIndex={9} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "gte", "value": "1", "errorMsg": "Value must be 1 or greater."}]' data-scope='{"group": "bizInfo", "field": "nOwners"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-state">State</label>
                            <select name="biz-state" id="biz-state" ref="biz-state" tabIndex={10} data-validate='{"type": "notEmpty", "errorMsg": "No state selected."}' data-scope='{"group": "bizInfo", "field": "state"}'  >
                            <option value="">--- Select State ---</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                            </select>
                        </div>   
                    </div>
                    <div className="form-row">
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-num-employees">Number of Employees</label><input type="number" name="biz-num-employees" id="biz-num-employees" ref="biz-num-employees" maxLength={8} min={0} tabIndex={11} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "gte", "value": "0", "errorMsg": "Value cannot be negative."}]' data-scope='{"group": "bizInfo", "field": "nEmployees"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-num-key-employees">Number of Key Employees</label><input type="number" name="biz-num-key-employees" id="biz-num-key-employees"  ref="biz-num-key-employees"  maxLength={8} min={0} tabIndex={12} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "gte", "value": "0", "errorMsg": "Value cannot be negative."}]' data-scope='{"group": "bizInfo", "field": "nKeyEmployees"}' />
                        </div> 
                        <div className="form-elem-unit column cols-2of6">
                            <label>Family Owned</label>
                            <input type="radio" name="biz-family-owned" id="biz-family-owned-yes" ref="biz-family-owned-yes" value="yes" tabIndex={13} data-validate='{"type": "notEmpty", "errorMsg": "Please select one option."}'  data-scope='{"group": "bizInfo", "field": "familyOwned"}' /><label htmlFor="biz-family-owned-yes">Yes</label>
                            <input type="radio" name="biz-family-owned" id="biz-family-owned-no" ref="biz-family-owned-no" value="no" tabIndex={14} data-validate='{"type": "notEmpty", "errorMsg": "Please select one option."}' data-scope='{"group": "bizInfo", "field": "familyOwned"}' /><label htmlFor="biz-family-owned-no">No</label>
                        </div>  
                    </div>
                    <h2>Business Financials</h2>
                    <div className="form-row">
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-corporate-assets">Corporate Assets</label><span className="input-unit-padding left">$</span><input type="text" pattern="number" name="biz-corporate-assets" id="biz-corporate-assets" ref="biz-corporate-assets" maxLength={24} className="has-units left" tabIndex={15} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "isNumDecimalComma", "errorMsg": "Input is not formatted as a number"}]' data-scope='{"group": "bizFinancials", "field": "corpAssets"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-corporate-liabilities">Corporate Liabilities</label><span className="input-unit-padding left">$</span><input type="text" pattern="number" name="biz-corporate-liabilities" id="biz-corporate-liabilities" ref="biz-corporate-liabilities" maxLength={24} className="has-units left" tabIndex={16} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "isNumDecimalComma", "errorMsg": "Input is not formatted as a number"}]' data-scope='{"group": "bizFinancials", "field": "corpLiabilities"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-annual-earnings">Annual Earnings</label><span className="input-unit-padding left">$</span><input type="text" pattern="number" name="biz-annual-earnings" id="biz-annual-earnings" ref="biz-annual-earnings" maxLength={24} className="has-units left" tabIndex={17} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "isNumDecimalComma", "errorMsg": "Input is not formatted as a number"}]'  data-scope='{"group": "bizFinancials", "field": "annualEarnings"}'/>
                        </div>
                        </div>
                        <div className="form-row">
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-cap-rate">Capitalization Rate</label><span className="input-unit-padding right">%</span><input type="text" pattern="number" name="biz-cap-rate" id="biz-cap-rate" ref="biz-cap-rate" maxLength={8} className="has-units right" tabIndex={18} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "isNumDecimal", "errorMsg": "Input is not properly formatted as a number"}, {"type": "gte", "value": "0", "errorMsg": "Value must be greater than or equal to 0%."}, {"type": "lt", "value": "100", "errorMsg": "Value must less than 100%."}]' data-scope='{"group": "bizFinancials", "field": "capRate"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-annual-ror">Expected Annual ROR</label><span className="input-unit-padding right">%</span><input type="text" pattern="number" name="biz-annual-ror" id="biz-annual-ror" ref="biz-annual-ror" maxLength={24} className="has-units right" tabIndex={19} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "isNumDecimal", "errorMsg": "Input is not properly formatted as a number"}, {"type": "gte", "value": "0", "errorMsg": "Value must be greater than or equal to 0%."}, {"type": "lt", "value": "100", "errorMsg": "Value must less than 100%."}]' data-scope='{"group": "bizFinancials", "field": "expAnnualROR"}' />
                        </div>
                        <div className="form-elem-unit column cols-2of6">
                            <label htmlFor="biz-goodwill-years">Years for Goodwill</label><input type="number" name="biz-goodwill-years" id="biz-goodwill-years" ref="biz-goodwill-years" maxLength={3} min={0} tabIndex={20} data-validate='[{"type": "notEmpty", "errorMsg": "This field cannot be empty."}, {"type": "isNumDecimal", "errorMsg": "Input is not properly formatted as a number"}, {"type": "gte", "value": "0", "errorMsg": "Value must be greater than or equal to 0."}]' data-scope='{"group": "bizFinancials", "field": "nYearsGoodwill"}' />
                        </div>
                    </div>
                    <h2>Report Output Options</h2>
                    <div className="form-row">
                        <div className="form-elem-unit column cols-5of6">
                            <label htmlFor="include-esop"><em>Include ESOP “What Should You Do With Your Business?” section:</em></label>
                            <input type="radio" name="include-esop" id="include-esop-yes" ref="include-esop-yes" value="yes" tabIndex={21} data-validate='{"type": "notEmpty", "errorMsg": "Please select one option."}'  data-scope='{"group": "reportOpts", "field": "esop"}' /><label htmlFor="include-esop-yes">Yes</label>
                            <input type="radio" name="include-esop" id="include-esop-no" ref="include-esop-no" value="no" tabIndex={22} data-validate='{"type": "notEmpty", "errorMsg": "Please select one option."}' data-scope='{"group": "reportOpts", "field": "esop"}' /><label htmlFor="include-esop-no">No</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-elem-unit column cols-5of6">
                            <label htmlFor="include-concepts"><em>Select at least one concept to include in “Protecting your Legacy” section:</em></label>
                            <div className="clear-both">
                                <input type="checkbox" name="include-concepts" id="include-concept-buy-sell" ref="include-concept-buy-sell" value="buy-sell" tabIndex={23} data-validate='{"type": "checkboxCheckedGT", "value": "0", "errorMsg": "At least one option must be checked"}' data-scope='{"group": "reportOpts", "field": "concepts"}' />
                                <label htmlFor="include-concept-buy-sell">Buy-Sell Agreement</label>
                            </div>
                            <div className="clear-both">
                                <input type="checkbox" name="include-concepts" id="include-concept-key-person" ref="include-concept-key-person" value="key-person-insurance" tabIndex={24} data-validate='{"type": "checkboxCheckedGT", "value": "0", "errorMsg": "At least one option must be checked"}' data-scope='{"group": "reportOpts", "field": "concepts"}' />
                                <label htmlFor="include-concept-key-person">Key Person Insurance</label>
                            </div>
                            <div className="clear-both">
                                <input type="checkbox" name="include-concepts" id="include-concept-disability-income-insurance" ref="include-concept-disability-income-insurance" value="disability-income-insurance" tabIndex={25} data-validate='{"type": "checkboxCheckedGT", "value": "0", "errorMsg": "At least one option must be checked"}' data-scope='{"group": "reportOpts", "field": "concepts"}' />
                                <label htmlFor="include-concept-disability-income-insurance">Disability Income Insurance</label>
                            </div>
                            <div className="clear-both">
                                <input type="checkbox" name="include-concepts" id="include-concept-recruitment-retention" ref="include-concept-recruitment-retention" value="recruitment-retention" tabIndex={26} data-validate='{"type": "checkboxCheckedGT", "value": "0", "errorMsg": "At least one option must be checked"}' data-scope='{"group": "reportOpts", "field": "concepts"}' />
                                <label htmlFor="include-concept-recruitment-retention">Recruitment and Retention</label>
                            </div>
                            <div className="clear-both">
                                <input type="checkbox" name="include-concepts" id="include-concept-life-insurance" ref="include-concept-life-insurance" value="life-insurance" tabIndex={27} data-validate='{"type": "checkboxCheckedGT", "value": "0", "errorMsg": "At least one option must be checked"}' data-scope='{"group": "reportOpts", "field": "concepts"}' />
                                <label htmlFor="include-concept-life-insurance">Life Insurance</label>
                            </div>
                            <div className="clear-both">
                                <input type="checkbox" name="include-concepts" id="include-concept-ilit" ref="include-concept-ilit" value="ilit" tabIndex={28} data-validate='{"type": "checkboxCheckedGT", "value": "0", "errorMsg": "At least one option must be checked"}' data-scope='{"group": "reportOpts", "field": "concepts"}' />
                                <label htmlFor="include-concept-ilit">ILIT</label>
                            </div>
                        </div>
                    </div>

                    {/* <div className="form-elem-unit column cols-5of6" style={{height:'250px'}}>
                        <select multiple name="cars" data-validate='{"type": "notEmpty", "errorMsg": "Select at least one option."}' data-scope='{"group": "reportOpts", "field": "cars"}' tabIndex={29} style={{height:'180px'}}>
                            <option value="volvo">Volvo</option>
                            <option value="saab">Saab</option>
                            <option value="opel">Opel</option>
                            <option value="audi">Audi</option>
                        </select>
                    </div>

                    <div className="form-elem-unit column cols-5of6" style={{height:'250px'}}>
                        <textarea name="textarea" cols="32" rows="6" data-validate='{"type": "notEmpty", "errorMsg": "Cannot be empty."}' data-scope='{"group": "reportOpts", "field": "longtext"}' tabIndex={30} style={{height:'180px'}}>
                            
                        </textarea>
                    </div> */}

                    <div className="form-buttons">
                        <button title="Save" tabIndex={29} onClick={this.saveData}>Save</button>
                        <button className="mint" title="Create Report" tabIndex={30} onClick={this.showReport}>Create Report</button>
                    </div>
                    
                </form>
            </div>
        )
    }

}

AppForm.contextType = AppProvider.appContext

export default AppForm