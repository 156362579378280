import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'

class Disability extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        return(
            <section className="disability-income-insurance toc-item" id="disability-income-insurance" data-toc-level="2" data-toc-heading="Business Protection: Disability Income Insurance" data-toc-show="yes" data-toc-style="default">
                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <h1>Disability Income<br />Insurance</h1>
                            <div className="column cols-4of6">
                                <h3>Keeping your business healthy</h3>
                                <p>A key individual doesn’t have to die to severely impact your business. Disability can hit your bottom line just as hard. Fortunately, Guardian offers a variety of best-in-class disability income products with unique features and competitive premiums to help:</p>
                                <ul>
                                    <li>Cover overhead expenses and fixed term payment obligations</li>
                                    <li>Fund buy-sell agreements</li>
                                </ul> 
                                <h4>Guardian employer-sponsored programs include:</h4>
                                <ul>
                                    <li>Qualified Sick Pay Program, which can be funded with disability income insurance</li>
                                    <li>Executive Bonus Program, which helps employers provide a bonus to key employees equal to the value of the disability income insurance premium</li>
                                </ul>     
                            </div>
                            
                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div> 
                </div>
            </section>
        )
    }

}

Disability.contextType = AppProvider.appContext
export default Disability