import React from 'react'
import {Component} from 'react'
import AppFormWrap from './components/AppFormWrap'
import ReportWrap from './components/ReportWrap'
import AppProvider from './modules/app-context'



class App extends Component {

  constructor(props){
      super(props)
      this.render = this.render.bind(this)
  }
  
  

  render = () => {
    return (
      
      
      <AppProvider>
        <div className="App">
            <AppFormWrap />
            <ReportWrap />
        </div>
      </AppProvider>
    )
  }
  
}

App.contextType = AppProvider.appContext

export default App
