import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types'
import AppProvider from '../modules/app-context'
import AppForm from './AppForm'


class AppFormWrap extends Component{

    

    render(){
        return(
            <div>
            {(!this.context.appState.printScreen) ? (<AppForm />): null}
            </div>
        )
    }
}
AppFormWrap.contextType = AppProvider.appContext
export default AppFormWrap