import React from 'react'
import { Component } from 'react'
import AppProvider from '../modules/app-context'

class PageHeader extends Component{
    render(){
        return(
            <header>{this.context.bizInfo.name}</header>
        )
    }
}

PageHeader.contextType = AppProvider.appContext

export default PageHeader