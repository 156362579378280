import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'

class ILIT extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        return(
            <section className="irrevocable-life-insurance-trust toc-item" id="irrevocable-life-insurance-trust" data-toc-level="2" data-toc-heading="Estate Preservation: Irrevocable Life Insurance Trust" data-toc-show="yes" data-toc-style="default">
                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <h1>Irrevocable Life<br />Insurance Trust</h1>
                        </div>

                        <div className="hangpoint hangpoint-3">
                            <div className="column cols-4of6">
                                <h2>Prioritizing family over taxes</h2>
                                <p>Is the bulk of your net worth tied up in your business? If so, you’re not alone. Assets in your business, including real estate, are primarily illiquid. And yet, upon your death, the IRS will require that estate taxes be paid, in cash, within nine months.</p>
                                <h3>What is an Irrevocable Life Insurance Trust (ILIT)?</h3>
                                <p>It’s an estate planning solution for high net worth individuals that anticipates the need for liquidity to pay estate taxes and administration expenses, and to provide cash to surviving family members.</p>
                                <h3>The benefits of an ILIT</h3>
                                <ul>
                                    <li>May provide much-needed liquidity to pay for estate tax and administrative obligations upon your death.</li>
                                    <li>May preserve loved ones’ liquid assets — savings and investment accounts, for example &mdash; which would otherwise be used for living expenses.</li>
                                    <li>May eliminate the need to sell off liquid assets at “fire sale” prices.</li>
                                </ul>
                            </div>


                            <h3>How does an ILIT work?</h3>
                            <p>Together with an estate planning attorney, you create an ILIT.</p>
                            <ul>
                                <li>The ILIT is funded with money that you gift to the trust.</li>
                                <li>The trustee then purchases life insurance on your life. (The trust may purchase insurance on the joint lives of you and your spouse.)</li>
                                <li>When you die, the trust receives the policy’s death benefit.</li>
                                <li>The trust now has cash to either make loans to your estate for the payment of estate taxes, or to purchase assets from your estate, converting illiquid assets to cash.</li>
                            </ul>
                            <p>The trust will hold money or assets from your estate, which are protected from creditors and can be used for the benefit of any of the trust beneficiaries. It may also ensure that your financial legacy to your family remains whole, undiminished by estate taxes.</p>
                                
                        </div>
                        <PageFooter sectionTitle="Protecting Your Legacy" />
                    </div> 
                </div>

                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <h3>How does an ILIT work?</h3>
                        </div>

                        <div className="hangpoint hangpoint-2">
                            <div className="column cols-3of6 float-left">
                                <figure className="overflow-visible align-center">
                                    <figcaption>At Inception</figcaption>
                                    <img src="img/ilit-inception.svg"  width="70%" alt="ILIT at inception"/>
                                </figure>
                            </div>

                            <div className="column cols-3of6 float-right">
                                <figure className="overflow-visible align-center">
                                    <figcaption>After Death</figcaption>
                                    <img src="img/ilit-death.svg" width="70%" alt="ILIT after death"/>
                                </figure>
                            </div>
                                
                        </div>
                        <PageFooter sectionTitle="Protecting Your Legacy" />
                    </div> 
                </div>
            </section>
        )
    }

}

ILIT.contextType = AppProvider.appContext
export default ILIT