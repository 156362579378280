import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types'
import AppProvider from '../modules/app-context'
import Report from './Report'
import { CSSTransition } from 'react-transition-group';

class ReportWrap extends Component{

    render(){
        return(
            <div>
                <CSSTransition in={this.context.appState.printScreen} timeout={500} unmountOnExit classNames="reportwrap">
                    <Report></Report>
                </CSSTransition>
            </div>
        )
    }
}
ReportWrap.contextType = AppProvider.appContext
export default ReportWrap