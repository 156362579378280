import React from 'react'
import { Component } from 'react'

const PageCountWrap = props =>{
    return(
        <div className="page-count">
            {props.children}
        </div>
    )
}

export default PageCountWrap