import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'
import BuySellAgreement from './BuySellAgreement'
import KeyPerson from './KeyPerson'
import Disability from './Disability'
import RecruitRetention from './RecruitRetention'
import LifeIns from './LifeIns'
import ILIT from './ILIT'

class ProtectingYourLegacy extends Component{
    
    constructor(props){
        super(props)
    }

    render(){

        return(
            <section className="protecting-your-legacy toc-item" id="protecting-your-legacy" data-toc-level="1" data-toc-heading="Protecting Your Legacy" data-toc-show="yes" data-toc-style="default">
            
                <div className="page has-masthead-img">
                    <div className="inner-wrap">
                        <img src="img/proecting-your-legacy-masthead.jpg" alt="" className="masthead" />
                        <div className="hangpoint hangpoint-5">
                            <h1>Protecting Your<br />Legacy</h1>
                            <div className="column cols-4of6">
                                <h2 style={{margin: '-.75em 0 2em 0 !important'}} >Which option is right for you?<br />Let’s work on that together.</h2>
                                
                                <p>You spent years building your business. Let’s spend a little time on the best way to get your business working for you and your family. We can help guide you to start thinking about the labyrinth of issues that can arise.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.context.reportOpts.concepts.includes('buy-sell')) ? (<BuySellAgreement />): null}
                {(this.context.reportOpts.concepts.includes('key-person-insurance')) ? (<KeyPerson />): null}
                {(this.context.reportOpts.concepts.includes('disability-income-insurance')) ? (<Disability />): null}
                {(this.context.reportOpts.concepts.includes('recruitment-retention')) ? (<RecruitRetention />): null}
                {(this.context.reportOpts.concepts.includes('life-insurance')) ? (<LifeIns />): null}
                {(this.context.reportOpts.concepts.includes('ilit')) ? (<ILIT />): null}
            </section>
        )
    }

}

ProtectingYourLegacy.contextType = AppProvider.appContext
export default ProtectingYourLegacy