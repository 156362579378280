import React from 'react'
import { Component, Fragment } from 'react'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'

class BuySellAgreement extends Component{
    
    constructor(props){
        super(props)
    }

    render(){
        let style1 = {
            marginTop: 1 + 'em'
        }

        return(
            <section className="buy-sell-agreements toc-item" id="buy-sell-agreements" data-toc-level="2" data-toc-heading="Business Succession: Buy-Sell Agreements" data-toc-show="yes" data-toc-style="default">
                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <h1>Buy-Sell<br />Agreements</h1>
                        </div>

                        <div className="hangpoint hangpoint-3">
                            <div className="column cols-3of6 float-left">
                                <h2>Protecting the business without you</h2>
                                <p>Take a minute to imagine your business without you. It’s hard, but it’s also inevitable. And the question that must be asked is:</p>
                                <p><strong>What would happen to your business if something happened to you or your partner(s)?</strong></p>
                                <p>One of you may die. You could become disabled. Or perhaps you’re in the best shape of your life, and you simply want to walk away. Together with your financial professional team, you can develop a business continuation strategy, with a properly funded buy-sell agreement.</p>
                                <h4>What is a buy-sell agreement?</h4>
                                <p>It’s a legal agreement specifying what happens to your business interest in the event you die, become disabled, or otherwise leave the business. The same is true for your co-owners. This agreement, when funded, can:</p>
                                <ul>
                                    <li>Create a ready market to purchase a business interest</li>
                                    <li>Establish a value for the purchase price of the business interest</li>
                                    <li>Identify the future buyer(s) &mdash; typically your co-owners or key employees</li>
                                    <li>Specify the events that would trigger the buy-sell agreement, such as death, disability or retirement</li>
                                    <li>Create a legal obligation for the departing owner to sell his or her business interest, and a legal obligation for the buyer to purchase the interest</li>
                                    <li>Provide a source of funds necessary to make the buy-sell arrangement effective</li>
                                </ul>
                            </div>
                            <div className="column cols-3of6 float-right">
                                <div className="sidebar fullwidth">
                                    <h4>The benefits for the departing owner and<br />his/her family:</h4>
                                    <ul>
                                        <li>Establishes a fair price for the business interest and removes uncertainty</li>
                                        <li>Provides liquidity for estate purposes</li>
                                        <li>Sets a value on the business interest for tax purposes</li>
                                        <li>Preserves business wealth and converts it into longtime family wealth</li>
                                    </ul>
                                        
                                    <h4>The benefits for the remaining owners:</h4>
                                    <ul>
                                        <li>Reassures creditors, employees and clients that a business will survive without a departing owner</li>
                                        <li>Removes the burden of paying a non-productive, disabled or retired owner</li>
                                        <li>Removes potential unwanted or unqualified family members from the operation of the business</li>
                                        <li>Protects the owners and their family members from a forced liquidation of the business</li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div>
                </div>

                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <div className="column cols-3of6 float-left">
                                <h3>Structuring your buy-sell agreement</h3>
                                <h4>Cross purchase buy-sell agreement</h4>
                                <ul>
                                    <li>Co-owner is specified as the buyer of a departing owner’s business interest.</li>
                                    <li>Agreement is solely between the co-owners of the business; the business itself is not a party to this agreement.</li>
                                </ul>
                                <h4>What you should know</h4>
                                <ul>
                                    <li>Purchasing owner receives an increase in the tax basis of his/her ownership interest equal to the fair market price paid for that interest.</li>
                                    <li>If the purchasing owner later decides to sell the business, future capital gains taxes are reduced.</li>
                                </ul>
                                <p>If your business is a sole proprietorship or single member LLC, the sale will, of necessity, be made to a third party. This is done through a “unilateral buy-sell agreement.”</p>
                                
                                <figure className="overflow-visible" style={{marginTop: '1em'}}>
                                    <img src="img/owners-beneficiary-buyout-transfer-diagram.svg" width="135%" alt="Owners beneficary, buyout and transfer diagram"/>
                                </figure>
                            </div>
                            <div className="column cols-3of6 float-right">
                                <figure className="bg-powder-blue fullwidth align-center"  style={{marginTop: '-1.2em !important'}}>
                                    <figcaption className="align-left">There are several ways to structure a buy-sell agreement. Choosing the right one depends
                                            on several factors:</figcaption>
                                    <img src="img/structuring-buy-sell-agreement.svg" className="fullwidth" alt="Ways of Structuring a Buy-Sell Agreement"/>
                                </figure>
                            </div>
                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div> 
                </div>
                

                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <div className="column cols-3of6 float-left">
                                <h3>Entity Purchase Buy-Sell Agreement</h3>
                                <ul>
                                    <li>Agreement is between the business and the individual owners; the business will be the buyer of a deceased/departing owner’s business interest.</li>
                                    <li>Considered a stock redemption agreement for incorporated businesses.</li>
                                </ul>

                                <h4>What you should know</h4>
                                <ul>
                                    <li>Business controls the administration and maintenance of the arrangement.</li>  
                                    <li>Although the remaining owners receive an increase in their respective ownership interests, there is no basis increase using this method.</li> 
                                    <li>For family-owned corporations, redemption of an owner’s stock may be treated as a dividend rather than a capital transaction; this may have tax implications.</li>
                                </ul>
                            </div>
                            <div className="column cols-3of6 float-right">
                                <figure className="fullwidth align-center"  style={{marginTop: '-1.2em !important'}}>
                                    <img src="img/entity-purchase-buy-sell-agreements.svg" className="fullwidth" alt="Entity Purchase Buy-Sell Agreements"/>
                                </figure>
                            </div>
                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div> 
                </div>
                

                <div className="page">
                    <div className="inner-wrap">
                        <PageHeader />
                        <div className="hangpoint hangpoint-1">
                            <div className="column cols-3of6 float-left">
                                <h3>Funding your buy-sell agreement</h3>
                                <p>The goal of any buy-sell agreement is to facilitate the orderly transition of your business. Properly funding your agreement is essential. An unfunded buy-sell agreement may cause severe financial hardship due to legal obligations that cannot be practicably fulfilled. </p>
                                <h3>What you should consider</h3>
                                <h4>Corporate cash flow/borrowing</h4>
                                <p>It must be sufficient to purchase a buy-sell agreement AND support new salaries, recruiting and training, despite an owner’s death/departure from the business.</p>
                                <p>Borrowing may also be more challenging after a departing event.</p> 
                                <h4>Installment sales</h4>
                                <p>May endanger the business’s viability and growth at a critical time, as installments may burden the business’s cash flow.</p> 
                                <p>Installment sales also carry an additional risk of default.</p>
                                <h4>Insurance</h4> 
                                <p>Many closely held businesses purchase a permanent life insurance policy to fund effective succession plans. Why?</p> 
                                <ul>
                                    <li>It helps build a balance sheet asset (i.e., the policy cash values)<sup>1</sup> that can be used to fund a buy-sell agreement due to a voluntary departure of an owner, supplemented by some of the other funding methods discussed above.</li>
                                    <li>An “Insurance-Only LLC” can be established to own the life insurance funding the buy-sell  agreement, providing an added measure of creditor protection.<sup>2</sup></li>
                                </ul>
                            </div>
                            <div className="column cols-3of6 float-right">
                                <figure className="bg-powder-blue fullwidth align-center"  style={{marginTop: '2.4em !important'}}>
                                        <figcaption className="align-left">Buy-sell agreements maybe funded by:</figcaption>
                                    <img src="img/funding-your-buy-sell-agreement.svg" className="fullwidth" alt="Funding your buy-sell agreement"/>
                                </figure>
                                <aside>
                                    <p>Insurance funds the buy-sell agreement precisely when it’s needed&mdash;at death or upon disability.</p>
                                </aside>
                            </div>
                        </div>

                        <div className="footnotes">
                            <p><sup>1</sup>Some whole life policies don’t have any cash value in years one or two. Whole life insurance should be considered for its long-term value. Early cash value accumulation and early payment of dividends depend upon policy type and/or policy design, and cash value accumulation is offset by insurance and company expenses. Consult with your Guardian representative and refer to your whole life insurance illustration for more information about your particular life insurance policy.</p>
                            <p><sup>2</sup> State creditor protection for life insurance policies varies by state. Contact your state’s insurance department or consult your legal advisor regarding your individual situation.</p>
                        </div>

                        <PageFooter sectionTitle="Protecting Your Legacy"/>
                    </div> 
                </div>
                
            </section>
        )
    }

}

BuySellAgreement.contextType = AppProvider.appContext
export default BuySellAgreement