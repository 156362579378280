import React from 'react'
import { Component, Fragment } from 'react'
// import PropTypes from 'prop-types'
import AppProvider from '../../modules/app-context'
import PageHeader from '../PageHeader'
import PageFooter from '../PageFooter'
import ReactEcharts from 'echarts-for-react'
import {stringCaseFormat, numberWithCommas, cleanNumberField, averageOfNums} from '../../modules/functions'

class AboutYourBusiness extends Component{
    
    constructor(props){
        super(props)
    }

    getOption(chartData){

        let currencyUnit = 'D'  // D, K, M, B for dollars, thousands, Millions
        let divisor = 1
        let chartDataArray = [chartData.bookValue, chartData.straightCapitalization, chartData.capitOfEarnings, chartData.yrsPurchaseTotal, chartData.avgBizValue]
        let adjustedDataArray 
        let largest = Math.max(...chartDataArray)


        if(largest > 900000000){
            console.log('billions')
            currencyUnit = 'B'
            divisor = 1000000000
        }
        else if(largest > 900000){
            console.log('millions')
            currencyUnit = 'M'
            divisor = 1000000
        }
        else if(largest > 900){
            console.log('thousands')
            currencyUnit = 'K'
            divisor = 1000
        }
  

        adjustedDataArray = chartDataArray.map((value) => value/divisor);




        let option = {
            xAxis: {
                type: 'category',
                data: ['BOOK\nVALUE', 'STRAIGHT\nCAPIT.', 'CAPIT.\nOF\nEARNINGS', 'YEARS\nPURCH.\nVALUE', 'AVG.\nBUSINESS\nVALUE'],
                axisLabel: {fontSize: 6, color: 'rgba(50,50,50,1)', show: false},
                axisTick: {show: false},
                axisLine: {
                    lineStyle: {
                        color: 'rgba(170,170,170,1)',
                        width: 1,
                        type: 'solid'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    fontSize: 9,
                    color: 'rgba(120,120,120,1)',
                    formatter: '${value}' + currencyUnit
                },
                axisTick: {show: false},
                axisLine: {show: false},
                splitLine: {
                    lineStyle: {
                        color: 'rgba(170,170,170,1)',
                        width: 1,
                        type: 'solid'
                    }
                }

            },
            series: [{
                data: adjustedDataArray,
                type: 'bar',
                itemStyle: {
                    normal: {
                        color: function(params) {
                            // build a color map as your need.
                            var colorList = [
                                'rgba(0,142,177,1)', 'rgba(13,63,94,1)', 'rgba(12, 167, 102,1)', 'rgba(148, 69, 109,1)', 'rgba(251, 170, 47,1)'
                            ];
                            return colorList[params.dataIndex]
                        }
                    }
                }
            }],
            grid: {
                top: 10,
                left: 15,
                right: 0,
                bottom: 5,
                containLabel: true
            }
        };
        
        
        
        return option
    }

    render(){
        let esop = this.context.reportOpts.esop
        let bizAssets = Number(cleanNumberField(this.context.bizFinancials.corpAssets))
        let bizLiabilities = Number(cleanNumberField(this.context.bizFinancials.corpLiabilities))
        let bookValue = bizAssets - bizLiabilities
        let annualEarnings = Number(cleanNumberField(this.context.bizFinancials.annualEarnings))
        let expectedROR = Number(this.context.bizFinancials.expAnnualROR) * .01
        let capRate = Number(this.context.bizFinancials.capRate) * .01
        let straightCapitalization = annualEarnings/capRate
        let earningsOnBkValue = (bizAssets - bizLiabilities) * expectedROR
        let excessEarnings = annualEarnings - earningsOnBkValue
        let capitExcessEarnings = excessEarnings/capRate
        let bookEarnings = excessEarnings + bookValue
        let capitOfEarnings = capitExcessEarnings + bookValue
        let goodWillEarnings = annualEarnings - earningsOnBkValue
        let goodWillYearsApplAmt = Number(this.context.bizFinancials.nYearsGoodwill) * goodWillEarnings
        let yrsPurchaseTotal = goodWillYearsApplAmt + bookValue
        let avgBizValue = averageOfNums(yrsPurchaseTotal, capitOfEarnings, straightCapitalization, bookValue)
        let chartData = {bookValue, straightCapitalization, capitOfEarnings, yrsPurchaseTotal, avgBizValue} //this obj is used to pass vars to getOptions() for chart
        


        return(
            <section className="about-your-business toc-item" id="about-your-business" data-toc-level="1" data-toc-heading="About Your Business" data-toc-show="yes" data-toc-style="default">
                
                <div className="page has-masthead-img">
                    <div className="inner-wrap">
                        <img src="img/about-your-business-masthead.jpg" className="masthead" />
                        <div className="hangpoint hangpoint-5">
                            <h1>About Your<br />Business</h1>
                        </div>
                    </div>
                </div>
                

                <section className="success-worth-protecting toc-item" id="success-worth-protecting" data-toc-level="2" data-toc-heading="Success Worth Protecting" data-toc-show="yes" data-toc-style="default">
                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <h1>Success worth<br />protecting</h1>
                            </div>
                            <div className="hangpoint hangpoint-3">
                                <div className="column cols-4of6">
                                    <p>It hasn’t been easy. But after years of long hours, sleepless nights and personal sacrifice, you’re finally seeing a day when you and your family are being rewarded.</p>
                                    <p>This is success worth protecting, and a future worth planning for. Effective business planning has three simple steps:</p>
                                    <strong>
                                        <ol>
                                            <li>Know what your business is worth.</li>
                                            <li>Protect all that has made your business successful.<br /><span className="non-bold">This includes not just your continued contributions, but those of key employees critical to your business’s future.</span></li>
                                            <li>Preserve your current and future lifestyle.<br /><span className="non-bold">Here, retirement, income protection and estate planning are powerful tools.</span></li>
                                        </ol>
                                    </strong>
                                    <h2>So, where do you start?</h2>
                                    <p>You already have! Based on the information you provided, we’ve created an informal valuation<sup>1</sup> of your business using the most appropriate valuation methods. Because only by having an accurate business valuation can we help create a plan to protect:</p>
                                    <strong>
                                        <ul>
                                            <li>You and your family</li>
                                            <li>Your key employees and potential partners/owners</li>
                                            <li>The continued health and success of your business</li>
                                        </ul>
                                    </strong>
                                </div>
                            </div>
                            <div className="footnotes">
                                    <p><sup>1</sup>This valuation should not be relied on for tax filing or other accounting purposes.</p>
                                </div>
                            <PageFooter sectionTitle="Business Valuation and Planning Profile" />
                        </div>
                    </div>
                    

                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <h2>What will this plan look like?</h2>
                            
                                <div className="column cols-4of6">
                                    <p>That’s up to you and your goals! Most often, such a plan includes:</p>
                                    <h3>A formal business succession plan</h3>
                                    <p>This is essential to a business’s future. Yet, nearly half of growing businesses fail to consider an exit or succession plan. Together with your financial professional team, Guardian has a variety of options to help plan (and, if necessary, fund) a succession plan that can protect you, your family, and your business.</p>
                                    <h3>Other strategies to protect your future</h3>
                                    <p>None of us can predict the future, but we can all plan for it. Changes in a company’s leadership (and ownership) often occur as a result of death or disability. Guardian can help protect your family and your lifestyle by planning for these events, too.</p>
                                </div>
                            </div>

                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    
                </section>

                
                <section className="valuing-your-business counted-headings toc-item" id="valuing-your-business" data-toc-level="2" data-toc-heading="Valuing Your Business" data-toc-show="yes" data-toc-style="default">
                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <h1>Valuing your<br />business</h1>
                            </div>
                            <div className="hangpoint hangpoint-3">
                                <div className="column cols-3of6 float-left">
                                    <p>It’s possible that your business may be worth much more (or much less) than you think.</p> 
                                    <p>Knowing its “fair market value” is critical to effective business planning. There are four generally accepted informal valuation methods. No one method is right for every business, and often, the most accurate valuation comes from averaging results of the methods below:</p>
                                    
                                    <h3 className="counted-heading"><span className="counter"></span>Book Value</h3>
                                    <h4>What it is</h4>
                                    <p>In simplest terms, book value is determined by the bottom line of the balance sheet &mdash; assets less liabilities.</p>
                                    <h4>When it’s used</h4>
                                    <p>For businesses with substantial fixed assets, like inventory and equipment.</p>
                                    <h4>What to consider</h4>
                                    <p>Few successful closely held companies change hands at book value. That’s because accounting methods often require assets to be valued at their <em>historical cost</em>, which could render a business’s value artificially low. Typically, assets such as plant, equipment, and real estate may not be adequately valued by this method when considering depreciation. More importantly, the <em>historical cost</em> may not reflect current fair market value of certain assets, such as real estate.</p>
                                    <p>In addition, problems may arise if the buyer and seller are related, since the IRS can claim that the transaction is not at arm’s-length. The IRS has had some success arguing that book value-based valuations represent attempts to pass businesses to children or other family members as disguised gifts or bequests. This, in turn, can cause gift or estate taxes to be owed by the seller.</p>
                                </div>
                                <div className="column cols-3of6 float-right">
                                    <figure className="bg-powder-blue fullwidth align-center">
                                        <figcaption className="align-center">Types of Valuation Methods</figcaption>
                                        <img src="img/figure-valuation-methods.svg" className="fullwidth" alt="Valuation Methods"/>
                                    </figure>
                                </div>
                            </div>

                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    

                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <div className="column cols-3of6 float-left">
                                    <p>Your business’s fair market value is driven by several factors:</p>
                                    <ul>
                                        <li>The state of the economy</li>
                                        <li>Whether or not you feel compelled to sell</li>
                                        <li>How quickly you identify a buyer</li>
                                        <li>The influence of an owner’s spouse, or of a minority owner</li>
                                        <li>Gift, estate, income and other tax considerations</li>
                                    </ul>
                                    <h3 className="counted-heading"><span className="counter"></span>Straight Capitalization</h3>
                                    
                                    <h4>What it is</h4>
                                    <div className="top-anchored-in-right-col">
                                        <table className="fullwidth no-header zebra zebra-blue zebra-even">
                                            <thead>
                                            <tr>
                                                <th>Capitalization<br />Rate</th>
                                                <th>Multiplier<br />(P/E)</th>
                                                <th>Earnings</th>
                                                <th>Estimated<br />Value</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr>
                                                <td>5%</td>
                                                <td>20</td>
                                                <td>$100,000</td>
                                                <td>$2,000,000</td>
                                            </tr>
                                            <tr>
                                                <td>8%</td>
                                                <td>12.5</td>
                                                <td>$100,000</td>
                                                <td>$1,250,000</td>
                                            </tr>
                                            <tr>
                                                <td>10%</td>
                                                <td>10</td>
                                                <td>$100,000</td>
                                                <td>$1,000,000</td>
                                            </tr>
                                            <tr>
                                                <td>12%</td>
                                                <td>8.33</td>
                                                <td>$100,000</td>
                                                <td>$833,000</td>
                                            </tr>
                                            <tr>
                                                <td>15%</td>
                                                <td>6.67</td>
                                                <td>$100,000</td>
                                                <td>$667,000</td>
                                            </tr>
                                            <tr>
                                                <td>20%</td>
                                                <td>5</td>
                                                <td>$100,000</td>
                                                <td>$500,000</td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    <p>This method weighs both prior earnings and income potential. It’s similar to the P/E ratio used to evaluate public companies. Here, the inverse of the P/E ratio is the capitalization rate (1 ÷ P/E = Capitalization Rate). A business with a P/E ratio of 20 has a Capitalization Rate of 5%. So, for example: $100,000 of Earnings ÷ 5% Capitalization Rate = $2 Million Business Value</p>
                                    <h4>When it’s used</h4>
                                    <p>For businesses with consistently strong earnings, and often few tangible assets.</p>
                                    <h4>What to consider</h4>
                                    <p>Selecting the most appropriate inputs for earnings and capitalization rates is key. A good starting point may be the pre-tax net earnings of the business for the year prior to the valuation date. However, an average of earnings over the past three to five years can sometimes provide a more accurate input, especially in fast-growing or cyclical businesses.</p>
                                    <p>The table to the right shows how even a fairly small change in the capitalization rate can multiply into very different values for a closely held business.</p>
                                    
                                    <h3 className="counted-heading"><span className="counter"></span>Capitalization of Earnings</h3>
                                    <h4>What it is</h4>
                                    <p>Similar to Straight Capitalization, this method weighs both prior earnings and income potential. It also uses a capitalization rate, but first adjusts the earnings for investment income, and also factors in book value.</p> 
                                </div>
                            </div>

                            
                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    

                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <div className="column cols-4of6">
                                <h4>When it’s used</h4>
                                <p>For businesses with consistently strong earnings, and also significant tangible assets.</p>
                                <h4>What to consider</h4>
                                <p>As with Straight Capitalization, selecting the most appropriate inputs for earnings and capitalization rates is key, and the same principals for choosing a capitalization rate apply.</p>
                                <h3 className="counted-heading"><span className="counter"></span>Years Purchase</h3>
                                <h4>What it is</h4>
                                <p>This method factors in the impact of goodwill into the business calculation, as well as its book value, adjusted earnings, and number of years in operation.</p>
                                <h4>When it’s used</h4>
                                <p>For businesses whose value is pegged to significant intangible assets, including:</p>
                                <ul>
                                    <li>An established and loyal customer base</li>
                                    <li>A strong and recognizable brand presence</li>
                                    <li>Specialized, knowledgeable, and/or experienced staff willing to stay on after the sale</li>
                                    <li>Protected intellectual property that gives the business an edge, such as patents and trademarks</li>
                                    <li>Business systems and processes that a new owner could easily take over</li>
                                </ul>
                                <h4>What to consider</h4>
                                <p>Care should be taken in estimating the number of years that this goodwill will last, as it can have a significant impact on the valuation.</p>
                                <p>The final approach to valuation is to get a formal appraisal.</p>

                                <h3 className="counted-heading"><span className="counter"></span>Appraised Value</h3>
                                <h4>What it is</h4>
                                <p>Hiring a qualified business appraiser.</p>
                                <h4>When it’s used</h4>
                                <p>For closely held businesses that are planning a sale, engaging in the succession planning process, implementing a buy-sell agreement, or developing an estate plan.</p>
                                <h4>What to consider</h4>
                                <p>The appraiser will use a variety of techniques, including sales of comparable businesses, to determine the ultimate value assigned to the business. This valuation is then integrated into customized succession, buy-sell, and estate plans. <strong>This report does not utilize a market-based valuation approach.</strong></p>
                                </div>
                            </div>
                            
                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    
                </section>
                
                <section className="business-profile toc-item" id="business-profile" data-toc-level="2" data-toc-heading="Business Profile: What You Told Us" data-toc-show="yes" data-toc-style="default">
                    
                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <h1>Business Profile</h1>
                            </div>
                            <div className="hangpoint hangpoint-3">
                                <div className="column cols-4of6">
                                    <h2>What you told us</h2>
                                    <table className="fullwidth zebra zebra-blue zebra-odd">
                                        <tbody>
                                        <tr>
                                            <td className="label">Business name</td>
                                            <td>{this.context.bizInfo.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Industry</td>
                                            <td>{this.context.bizInfo.industry}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Tax Type</td>
                                            <td>{this.context.bizInfo.taxType}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">State</td>
                                            <td>{this.context.bizInfo.state}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Number of years in business</td>
                                            <td>{this.context.bizInfo.nYearsInBiz}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Number of owners</td>
                                            <td>{this.context.bizInfo.nOwners}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Number of employees</td>
                                            <td>{this.context.bizInfo.nEmployees}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Number of key employees</td>
                                            <td>{this.context.bizInfo.nKeyEmployees}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Family owned?</td>
                                            <td>{stringCaseFormat(this.context.bizInfo.familyOwned, 'initialCaps')}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <p>The following information you supplied was used to calculate the business valuations on the subsequent pages:</p>
                                    
                                    <table className="fullwidth zebra zebra-odd zebra-green">
                                        <tbody>
                                        <tr>
                                            <td className="label">Assets</td>
                                            <td>{'$' + numberWithCommas(cleanNumberField(this.context.bizFinancials.corpAssets))}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Liabilities</td>
                                            <td>{'$' + numberWithCommas(cleanNumberField(this.context.bizFinancials.corpLiabilities))}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Earnings</td>
                                            <td>{'$' + numberWithCommas(cleanNumberField(this.context.bizFinancials.annualEarnings))}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Capitalization rate</td>
                                            <td>{this.context.bizFinancials.capRate + '%'}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Expected ROR</td>
                                            <td>{this.context.bizFinancials.expAnnualROR + '%'}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Years for Goodwill</td>
                                            <td>{this.context.bizFinancials.nYearsGoodwill}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
            
                            
                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    
                </section>
                
                <section className="business-valuation toc-item" id="business-valuation" data-toc-level="2" data-toc-heading="Your Business Valuation" data-toc-show="yes" data-toc-style="default">
                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <div><h2>Your Business Valuation</h2></div>
                                <div className="column cols-3of6 float-left">
                                    <h3>Book Value</h3>
                                    <p>Assets, less liabilities&mdash;often, a “liquidation” value</p>
                                    <table className="fullwidth zebra zebra-green zebra-odd no-bottom-rule">
                                        <tbody>
                                        <tr>
                                            <td className="label">Business assets</td>
                                            <td>{'$' + numberWithCommas(bizAssets)}</td>
                                        </tr>
                                        <tr className="bottom-ruled">
                                            <td className="label">Less business liabilities</td>
                                            <td><span className="in-the-red">{'-$' + numberWithCommas(bizLiabilities)}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="label">Total value</td>
                                            <td>{'$' + numberWithCommas(bookValue) }</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <h3>Capitalization of Earnings</h3>
                                    <p>Earnings, less adjustments, plus book value (above)</p>
                                    <table className="fullwidth zebra zebra-green zebra-odd no-bottom-rule">
                                        <tbody>
                                        <tr>
                                            <td className="label">Annual earnings</td>
                                            <td>{'$' + numberWithCommas(annualEarnings)}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Less earnings on book value</td>
                                            <td><span className="in-the-red">{'-$' + numberWithCommas(Math.round(earningsOnBkValue))}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="label">Excess earnings</td>
                                            <td>{'$' + numberWithCommas(Math.round(excessEarnings))}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Capitalized excess earnings</td>
                                            <td>{'$'+ numberWithCommas(Math.round(capitExcessEarnings))}</td>
                                        </tr>
                                        <tr className="bottom-ruled">
                                                <td className="label">Plus book value</td>
                                                <td><span className="in-the-green">{'+' + numberWithCommas(Math.round(bookValue))}</span></td>
                                            </tr>
                                        <tr>
                                            <td className="label">Total value</td>
                                            <td>{'$' + numberWithCommas(Math.round(capitOfEarnings))}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="column cols-3of6 float-right">
                                        <h3>Straight Capitalization</h3>
                                        <p>Earnings, divided by the capitalization rate</p>
                                        <table className="fullwidth zebra zebra-green zebra-odd no-bottom-rule">
                                            <tbody>
                                            <tr>
                                                <td className="label">Annual earnings</td>
                                                <td>{'$' + numberWithCommas(annualEarnings)}</td>
                                            </tr>
                                            <tr className="bottom-ruled">
                                                <td className="label">Capitalization rate</td>
                                                <td>{this.context.bizFinancials.capRate + '%'}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">Total value</td>
                                                <td>{'$' + numberWithCommas(Math.round(straightCapitalization)) }</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <h3>Years Purchase</h3>
                                        <p>&nbsp;</p>
                                        <table className="fullwidth zebra zebra-green zebra-odd no-bottom-rule">
                                            <tbody>
                                            <tr>
                                                <td className="label">Annual earnings</td>
                                                <td>{'$' + numberWithCommas(annualEarnings)}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">Less earnings on book value</td>
                                                <td><span className="in-the-red">{'-$' + numberWithCommas(Math.round(earningsOnBkValue))}</span></td>
                                            </tr>
                                            <tr>
                                                <td className="label">Goodwill earnings</td>
                                                <td>{'$' + numberWithCommas(Math.round(goodWillEarnings))}</td>
                                            </tr>
                                            <tr>
                                                <td className="label">Goodwill years applied amount</td>
                                                <td>{'$' + numberWithCommas(Math.round(goodWillYearsApplAmt))}</td>
                                            </tr>
                                            <tr className="bottom-ruled">
                                                    <td className="label">Plus book value</td>
                                                    <td><span className="in-the-green">{'$' + numberWithCommas(Math.round(bookValue))}</span></td>
                                                </tr>
                                            <tr>
                                                <td className="label">Total value</td>
                                                <td>{'$' + numberWithCommas(Math.round(yrsPurchaseTotal))}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                </div>
                                
                            </div>
                            <div className="hangpoint hangpoint-6" style={{marginTop: '7em'}}>
                                <div className="column cols-3of6 float-left">
                                    <h3>Average business value</h3>
                                    <div className="summary-figure">{'$' + numberWithCommas(Math.round(avgBizValue))}</div>
                                </div>

                                <div className="column cols-3of6 float-right" id="valuation-chart-div">
                                    <h3>Your business valuation</h3>
                                    
                                    <ReactEcharts
                                        option={this.getOption(chartData)}
                                        style={{height: '160px', width: '60%', padding: 0 }}
                                        opts={{renderer: 'svg'}}
                                    />
                                    <ul className="legend">
                                        <li class="book-value">Book value</li>
                                        <li class="straight-cap">Straight Capitalization</li>
                                        <li class="cap-earnings">Capitalization of earnings</li>
                                        <li class="yrs-purchase">Years purchase value</li>
                                        <li class="avg-biz-val">Average business value</li>  
                                    </ul>
                                </div>
                            </div>
            
                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    
                </section>
                
                
                <section className="what-should-you-do-with-your-business counted-headings toc-item" id="what-should-you-do-with-your-business" data-toc-level="2" data-toc-heading="Your Business: What Should You Do With It?" data-toc-show="yes" data-toc-style="default">
                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <h1>What should you do<br />with your business?<sup>2</sup></h1>
                            </div>
                            <div className="hangpoint hangpoint-3">
                                <div className="column cols-4of6">
                                    <p>It’s the question every owner of a closely held business must face.</p>
                                    <p>Let’s answer it together, with a plan based on:</p>
                                    <ul>
                                        <li>Your business’s true market value</li>
                                        <li>Your vision for the company that you’ve worked so hard to build</li>
                                        <li>Your desire to reward yourself and your family, and to protect your future lifestyle</li>
                                    </ul>
                                </div>
                            </div>
            
                            <div className="footnotes">
                                    <p><sup>2</sup>Each of the options discussed herein will have legal and tax consequences, and you should consult with your legal and tax advisors to learn more before moving forward.</p>
                                </div>
                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    

                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <div className="column cols-4of6">
                                    <h3 className="counted-heading"><span className="counter"></span>Transfer to family members</h3>
                                    <p>As the owner of a closely held business, you’ve probably considered this option. Many of your peers transfer ownership within their families in one of three ways:</p>
                                    <ul>
                                        <li>Gift the entire interest in the business to family members, effectively transferring immediate ownership and control</li>
                                        <li>Gift a percentage of ownership gradually over time</li>
                                        <li>Keep the business until death, at which point it transfers to family members</li>
                                    </ul>
                                    <p>Each presents its own issues to consider:</p>
                                    <ul>
                                        <li>With an immediate or gradual transfer, you receive nothing of value in exchange, which can be difficult as many business owners look at their business as their primary source of retirement income. Such transfers may also trigger gift taxes.</li>
                                        <li>A transfer upon death does provide ownership at a basis equal to fair market value, and little to no capital gains taxes may be owed if the business was eventually sold soon after the death.  HOWEVER, the entire value of the business can be included in your estate for estate tax purposes. Having sufficient life insurance in place can help provide your family with needed liquidity, through an income tax-free death benefit.</li>
                                    </ul>
                                    <p><strong>Are family members ready to assume control of your business? If not, consider the following options:</strong></p>
                                    
                                    <h3 className="counted-heading"><span className="counter"></span>Sale to outsiders</h3>
                                    <p>This option may put the most money in your pocket, but how much will you really net after capital gains taxes?</p>
                                    <p>Also, expect the new owners to ask you to stick around for 3-5 years in what is called an “earn out,” where you can receive a lump sum up front and the balance over your earn-out period. Your attorney should be involved here, should you need to take back the company if the buyers default on any of their contractual obligations.</p>
                                    
                                    <h3 className="counted-heading"><span className="counter"></span>Sale to insiders</h3>
                                    <p>A sale to family members, key employees and/or others close to the business benefits from potential new owners who are passionate about the company. Still, this is seldom an all-cash transaction, and you should be prepared to step back in should the business go sideways and the new owners are unable to make negotiated installment payments.</p>
                            
                                </div>
                            </div>
                            
                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                    

                    <div className="page">
                        <div className="inner-wrap">
                            <PageHeader />
                            <div className="hangpoint hangpoint-1">
                                <div className="column cols-3of6 float-left">
                                    {(() => {
                                        if(esop === 'yes') return(
                                            <Fragment>
                                            <h3 className="counted-heading"><span className="counter"></span>Employee Stock Ownership Plan (ESOP)</h3>
                                            <p>Think of an ESOP as an employee benefit that’s funded by the business, making it a great way to retain and reward employees. The sale can be all at once or gradual, and for as little or as much of the stock as you want to part with. You may choose to stay with the business and even retain operating control.</p> 
                                            <p>ESOPs are usually funded in one of two ways:</p>
                                            <ol>
                                                <li>The company makes tax-deductible cash contributions to an ESOP trust, which the trust then uses to gradually purchase your stock.</li>
                                                <li>The ESOP borrows funds needed to buy your shares; a bank grants a loan to the company, which then re-loans to the ESOP to purchase your stock.</li>
                                            </ol>
                                            <p>Either way, stock is held in a trust for and allocated to employees based on a formula, and is then distributed after the employee separates from service.</p>
                                            </Fragment>
                                            )
                                        }

                                    )()}
                                            
                                    <h3 className="counted-heading"><span className="counter"></span>Private equity transaction</h3>
                                    <p>What if your successful business attracts the attention of a venture capital firm? While this could provide you with a significant liquidity event, you might have to watch your “baby” be run by someone else. Some owners find that too difficult, leading them to leave the company or try buying it back.</p>
                                </div>

                                <div className="column cols-3of6 float-right">
                                    <aside>
                                        {(() => {
                                                if(esop === 'yes'){
                                                    return(<p>Typically, companies that are a good candidate for an ESOP have revenue of at least $2 million and 20+ employees.</p>)
                                                }
                                                else{
                                                    return(<p>Could you watch your &ldquo;baby&rdquo; be run by someone else? Consider the pros and cons of VC involvement.</p>)
                                                }
                                            }

                                        )()}
                                    </aside>
                                </div>
                            </div>
                            
                            
                            <PageFooter sectionTitle="Business Valuation and Planning Profile"  />
                        </div>
                    </div>
                </section>
            </section>
        )
    }

}

AboutYourBusiness.contextType = AppProvider.appContext
export default AboutYourBusiness